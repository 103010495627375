import { forwardRef } from 'react';

const base = 'btn waves-effect waves-light';

const variants = {
  primary: 'btn-primary',
  secondary: 'btn-secondary',
};

const Button = forwardRef(
  ({ variant = 'primary', className, children, ...props }, ref) => (
    <button
      ref={ref}
      className={`${base} ${variants[variant]} ${className}`}
      type='submit'
      {...props}
    >
      {children}
    </button>
  ),
);

Button.displayName = 'Button';

export { Button };
