import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';

function GenericModal(props) {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  const afterClosed = () => {
    props.callback({ isOpen: false, entity: null });
  };

  const toggle = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Modal
        size={props.size ? props.size : null}
        isOpen={isOpen}
        toggle={toggle}
        onClosed={afterClosed}
        modalClassName='drawer right-align'
      >
        <div
          className='modal-header'
          style={props.title ? {} : { border: 'none' }}
        >
          {props.title && <h5 className='modal-title mt-0'>{props.title}</h5>}
          <button
            type='button'
            onClick={() => {
              toggle();
            }}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>

        <div className='modal-body' style={{ border: 'none' }}>
          {props.children}
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default withTranslation()(GenericModal);
