import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';

import { List } from 'phosphor-react';
import Swal from 'sweetalert2';

import $filter from '@cash2pay/common/utils/$filter';
import { BLOCKED_CREDIT } from '@cash2pay/domains/CreditDomain';
import { USER_PROFILES } from '@cash2pay/domains/UserProfileDomain';
import { getSessionUserProfile, hasRole } from '@cash2pay/helpers/api_helper';
import { showToast } from '@cash2pay/libs/toast';
import AnticipationContractModal from '@cash2pay/pages/Contracts/AnticipationContract/AnticipationContract';

export const UserIdentifierPanel = (props) => {
  const [userProfile] = useState(getSessionUserProfile());

  const [modalNewAnicipationContract, setModalNewAnicipationContract] =
    useState(false);

  const translation = useTranslation();

  const isAntecipateButtonDisabled =
    !hasRole('contract.write') || props.entity.balanceAvailable <= 0;

  const isDesactiveScheduleButtonDisabled =
    !hasRole('optin.write') || props.isOptout;

  const closeModalNewAnicipationContract = () => {
    setModalNewAnicipationContract(false);
  };

  const openModalNewAnicipationContract = () => {
    if (props.isOutdated) {
      showToast({
        type: 'info',
        message:
          'A agenda de recebíveis deve estar atualizada para a datade hoje.',
      });
      return;
    }
    setModalNewAnicipationContract(true);
  };

  const handleDesactiveSchedule = async () => {
    const html = `
      <div>
        <p>${translation.t('Suspend access permission to')}<br/>
          <strong>${props.entity.receivingEndUser.name}</strong><br/>
          ${translation.t('calendar through opt-out')}.
        </p>
      </div>
    `;

    Swal.fire({
      title: translation.t('Confirm o opt-out?'),
      icon: 'question',
      html: html,
      showCloseButton: false,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: translation.t('Confirm'),
      cancelButtonText: translation.t('Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) props.onCreateOutput();
    });
  };

  let firstKpi = {
    label: 'Saldo disponivel',
    amount: $filter(props.entity.balanceAvailable, 'MONEY'),
  };
  let secondKpi = {
    label: 'Valor comprometido',
    amount: $filter(props.entity.compromisedValue, 'MONEY'),
  };

  if (userProfile === USER_PROFILES.SELLER) {
    firstKpi = {
      label: 'Limite disponível',
      amount: $filter(props.entity.creditLimit, 'MONEY'),
    };
    secondKpi = {
      label: 'Limite tomado',
      amount: $filter(props.entity.creditLimitCompromised, 'MONEY'),
    };
  }

  return (
    <>
      <Card
        style={{
          marginRight: -22,
          marginLeft: -22,
          paddingLeft: 22,
          paddingRight: 22,
        }}
      >
        <CardBody>
          <Row>
            <Col
              lg={12}
              className='d-flex align-items-start justify-content-between'
            >
              <div className='d-flex align-items-center' style={{ gap: 44 }}>
                <div>
                  <p className='text-muted mb-0'>
                    {props.entity.receivingEndUser.legalName}
                  </p>
                  <h4>{props.entity.receivingEndUser.name}</h4>
                  <p className='text-muted mb-0'>
                    CPF/CNPJ:{' '}
                    {$filter(props.entity.receivingEndUser.legalId, 'CPF_CNPJ')}
                  </p>
                </div>
                <blockquote className='blockquote font-size-16 mb-0 ms-5'>
                  <p className='text-muted mb-0'>{firstKpi.label}</p>
                  <h4>{firstKpi.amount}</h4>
                </blockquote>
                <blockquote
                  className='blockquote font-size-16 mb-0'
                  style={{ borderLeft: '4px solid #f56e50' }}
                >
                  <p className='text-muted mb-0'>{secondKpi.label}</p>
                  <h4>{secondKpi.amount}</h4>
                </blockquote>
              </div>

              <UncontrolledDropdown>
                <DropdownToggle tag='button' className='btn px-3'>
                  <List size={24} />
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-end'>
                  <DropdownItem header>Agenda de Recebíveis</DropdownItem>
                  <DropdownItem
                    id='tooltip-antecipate'
                    disabled={isAntecipateButtonDisabled}
                    onClick={openModalNewAnicipationContract}
                  >
                    Antecipar
                  </DropdownItem>
                  <DropdownItem
                    disabled={isDesactiveScheduleButtonDisabled}
                    onClick={handleDesactiveSchedule}
                  >
                    Desativar Atualização
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>

          {props.entity['blockedCredit'] === BLOCKED_CREDIT.YES && (
            <Row className='mt-4'>
              <Col lg={12}>
                <Alert color='warning' role='alert'>
                  <i className='mdi mdi-calendar-lock font-size-20' />
                  &nbsp;&nbsp;
                  <span>Estabelecimento comercial com </span>
                  <strong>
                    Bloqueio de Agenda ({props.entity['tpBlockedCredit']})
                  </strong>
                  <span>
                    , para operações de crédito é necessário remover os
                    bloqueios. Solicite orientação para equipe de Suporte de
                    Negócio
                  </span>
                </Alert>
              </Col>
            </Row>
          )}

          {!props.entity['lastUpdateDate'] && props.entity['hasLoadedApi'] && (
            <Row className='mt-4'>
              <Col lg={11}>
                <Alert color='info' role='alert'>
                  <i className='mdi mdi-clock font-size-20' />
                  &nbsp;&nbsp;
                  <span>Estabelecimento comercial </span>
                  <strong>sem Agenda de Recebíveis</strong>
                  <span>
                    , até o nomento não recebemos as agendas das maquininhas de
                    cartão.
                  </span>
                </Alert>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>

      {modalNewAnicipationContract && (
        <AnticipationContractModal
          isOpen={modalNewAnicipationContract}
          receivableUnits={props.entity.receivableUnitsAvailable}
          receivingEndUser={Object.assign(
            {
              bankAccounts: props.entity.bankAccounts,
            },
            props.entity.receivingEndUser,
          )}
          financialAgent={props.entity.financialAgent}
          callback={(entity) => {
            closeModalNewAnicipationContract(entity);
          }}
        />
      )}
    </>
  );
};
