import { isDev } from './config';

const IS_DEV = isDev;

const APP_DEV_URL =
  process.env['NODE_ENV'] === 'production'
    ? 'https://front.hml.cash2pay.com.br'
    : 'http://localhost:3000';
export const APP_URL = isDev ? APP_DEV_URL : 'https://app.cash2pay.com.br';

const ARP_API_URL = `http://localhost:5002`;
const API_CONTRACT_FORMALIZATION = `${
  IS_DEV
    ? 'https://api.hml.cash2pay.com.br/contract-formalization-manager'
    : 'https://api.cash2pay.com.br/contract-formalization-manager'
}`;
const API_OPTIN = `${
  IS_DEV
    ? 'https://api.hml.cash2pay.com.br/cpay-optin-api'
    : 'https://api.cash2pay.com.br/cpay-optin-api'
}`;
const API_OPTIN_FORMALIZATION = `${
  IS_DEV
    ? 'https://api.hml.cash2pay.com.br/optin-formalization-manager'
    : 'https://api.cash2pay.com.br/optin-formalization-manager'
}`;
const API_BILLING_MANAGER = `https://${
  IS_DEV ? 'api.hml' : 'api'
}.cash2pay.com.br/c2p-billing-manager`;
const API_ORDER = `https://${
  IS_DEV ? 'api.hml' : 'api'
}.cash2pay.com.br/c2p-orders`;
const API_GLOBAL_PARAMS = `https://${
  IS_DEV ? 'api.hml' : 'api'
}.cash2pay.com.br/global-params`;
const API_LIQ = `https://${
  IS_DEV ? 'api.hml' : 'api'
}.cash2pay.com.br/liquidations`;
const API_BANKING = `https://${
  IS_DEV ? 'api.hml' : 'api'
}.cash2pay.com.br/banking`;
const API_CONTRACT = `https://${
  IS_DEV ? 'api.hml' : 'api'
}.cash2pay.com.br/contract-ops`;

export const API_GRAVAME = 'http://localhost:5001/v1/api';

//LOGIN
export const POST_LOGIN = `${ARP_API_URL}/arp/api/userLogin`;
export const POST_CHANGE_COMPANY = `${ARP_API_URL}/arp/api/userLogin/change-company`;

//PASSWORD RECOVER
export const POST_CONFIRM_EMAIL = `${ARP_API_URL}/arp/api/access-management/confirm-email`;
export const POST_CONFIRM_TWO_FACTOR = `${ARP_API_URL}/arp/api/access-management/confirm-two-factor`;
export const POST_CHANGE_PASSWORD = `${ARP_API_URL}/arp/api/access-management/change-password`;
export const POST_REQUEST_NEW_PASSWORD = `${ARP_API_URL}/arp/api/access-management/recover-password`;

//CONTRACT
export const GET_CONTRACT = `${ARP_API_URL}/arp/api/v1/contract`;
export const POST_CONTRACT = `${ARP_API_URL}/arp/api/v1/contract`;
export const GET_CONTRACT_TERMS = `${API_CONTRACT_FORMALIZATION}/contract/formalization/pdf`;
export const REQUEST_CONTRACT_SIGNING = `${API_CONTRACT_FORMALIZATION}/contract/formalization/requestSign`;

//OPTIN
export const GET_OPTIN = `${API_OPTIN}/api/v1/optin`;
export const GET_OPTIN_FULL = `${API_OPTIN}/api/v1/optin/all`;
export const POST_OPTIN = `${API_OPTIN}/api/v1/optin`;
export const POST_OPTOUT = `${API_OPTIN}/api/v1/optout`;
export const POST_OPTOUT_FULL = `${API_OPTIN}/api/v1/completeOptout`;
export const GET_OPTIN_TERMS = `${API_OPTIN_FORMALIZATION}/optin/formalization/pdf`;

//SCHEDULE
export const GET_RECEIVABLES = `${ARP_API_URL}/arp/api/v1/receivables`;

export const GET_RECEIVABLES_AVAILABLE = `${ARP_API_URL}/arp/api/v1/receivable-available`;
export const GET_RECEIVABLES_COMPROMISED = `${ARP_API_URL}/arp/api/v1/compromised-receivables`;
export const GET_FUTURE_COMMITMENTS = `${ARP_API_URL}/arp/api/v1/future-commitments`;

export const RECEIVABLES_AVAILABLE = `${API_GRAVAME}/receivable/list/available`;
export const RECEIVABLES_COMPROMISED = `${API_GRAVAME}/receivable/list/compromised-receivables`;
export const FUTURE_COMMITMENTS = `${API_GRAVAME}/receivable/list/future-commitments`;

export const GET_ACQUIRERS_BY_MONTHS = `${ARP_API_URL}/arp/api/v1/acquirers-bymonths`;
export const GET_END_USER_OBLIGATIONS = `${ARP_API_URL}/arp/api/v1/end-user-obligations`;

//DASHBOARD
export const GET_PORTFOLIO_REPORT = `${ARP_API_URL}/arp/api/v1/portfolio-report`;

//BILLING
export const POST_BILLING = `${API_BILLING_MANAGER}/register-billing`;
export const POST_BILLING_PDF = `${API_BILLING_MANAGER}/pdf`;

// USERS
export const GET_USERS = `${ARP_API_URL}/arp/api/v1/user`;
export const POST_USERS = `${ARP_API_URL}/arp/api/v1/user`;
export const PUT_USERS = `${ARP_API_URL}/arp/api/v1/user`;

// ORGANIZATION
export const GET_ORGANIZATION = `${ARP_API_URL}/arp/api/v1/org`;
export const POST_ORGANIZATION = `${ARP_API_URL}/arp/api/v1/org`;
export const PUT_ORGANIZATION = `${ARP_API_URL}/arp/api/v1/org`;
export const GET_MY_ORGANIZATION = `${ARP_API_URL}/arp/api/v1/org/my-org`;

// GLOBAL PARAMS
export const GET_GLOBAL_PARAMS = `${API_GRAVAME}/global-params/filter/pagination`;
export const PATCH_GLOBAL_PARAMS = `${API_GRAVAME}/global-params/update`;

//SIGNERS
export const GET_SIGNERS = `${ARP_API_URL}/arp/api/v1/org/signers`;
export const PUT_SIGNERS = `${ARP_API_URL}/arp/api/v1/org/signers`;

//ORDERS
export const GET_ORDER = `${API_ORDER}/order`;
export const POST_ORDER = `${API_ORDER}/order`;
export const PATCH_ORDER = `${API_ORDER}/order`;
export const GET_ORDER_FORMALIZATION = `${API_ORDER}/order/formalization`;
export const POST_ORDER_BILLING = `${API_BILLING_MANAGER}/order/register-billing`;
export const POST_ORDER_BILLING_PDF = `${API_BILLING_MANAGER}/order/pdf`;

//CHECKOUT
export const GET_CHECKOUT = `${API_ORDER}/order/checkout`;
export const POST_INI_CHECKOUT = `${API_ORDER}/order/init-checkout`;

// SEGMENT
export const GET_SEGMENTS = `${ARP_API_URL}/arp/api/v1/segment`;
export const PUT_SEGMENT = `${ARP_API_URL}/arp/api/v1/segment`;
export const POST_SEGMENT = `${ARP_API_URL}/arp/api/v1/segment`;
export const GET_SEGMENT_COMPANY = `${ARP_API_URL}/arp/api/v1/org-segment`;

// LIQUIDATION
export const GET_LIQUIDATIONS = `${API_LIQ}/expected`;
export const GET_LIQUIDATIONS_CTR = `${API_LIQ}/expected/by-ctr`;
export const GET_LIQUIDATIONS_CTR_CSV = `${API_LIQ}/expected/by-ctr/csv`;
export const POST_LIQUIDATION_CTR = `${API_LIQ}/manual-ctr-liquidation`;

//CNAB
export const GET_CNAB444_REMESSA = `${API_BANKING}/cnab444/remessa`;
export const GET_CNAB444_PAGAMENTO = `${API_BANKING}/cnab444/baixa`;

//CONTRACT OPS
export const GET_CONTRACTOR_ARRANGEMENT = `${API_CONTRACT}/arrangement`;
export const GET_CONTRACTOR_ACQUIRER = `${API_CONTRACT}/acquirer`;

export const GET_OPTIN_ADMIN = `${API_GRAVAME}/optin/list`;
export const GET_RECEIVING_END_USER_PAGINATION = `${API_GRAVAME}/people/filter/pagination/receiving-end-user`;

export const GET_FINANCIAL_AGENT_PAGINATION = `${API_GRAVAME}/people/filter/pagination/financial-agent`;

export const GET_ORDER_FINANCIAL_AGENT_PAGINATION = `${API_GRAVAME}/people/filter/pagination/order-financial-agent`;

export const GET_ORDER_BUYER_AGENT_PAGINATION = `${API_GRAVAME}/people/filter/pagination/order-buyer-agent`;

export const GET_ORDER_SELLER_AGENT_PAGINATION = `${API_GRAVAME}/people/filter/pagination/order-seller-agent`;

export const GET_BILLING_BUYER_AGENT = `${API_GRAVAME}/people/filter/pagination/billing-seller-agent`;

export const GET_BILLING_SELLER_AGENT = `${API_GRAVAME}/people/filter/pagination/billing-seller-agent`;

export const GET_PAG_PEOPLE = `${API_GRAVAME}/people/filter/pagination/all`;

export const GET_CONTRACT_BUYER_AGENT = `${API_GRAVAME}/people/filter/pagination/contract-seller-agent`;

export const GET_ORDER_ADMIN = `${API_GRAVAME}/order/list`;

export const GET_ORDER_ADMIN_DETAILS = `${API_GRAVAME}/order/details`;

export const GET_CONTRACT_ADMIN = `${API_GRAVAME}/contract/list`;

export const GET_BILLING_ADMIN = `${API_GRAVAME}/billing/list`;

export const GET_BILLING_ADMIN_DETAILS = `${API_GRAVAME}/billing/details`;

export const GET_BILLING_ADMIN_PAYMENTS = `${API_GRAVAME}/billing/payment`;

export const GET_OPTIN_ADMIN_BY_ID = `${API_GRAVAME}/optin/details`;

export const GET_PEOPLE_ADMIN_BY_ID = `${API_GRAVAME}/people/details`;

export const POST_OPTIN_SYNC_DATA = `${API_GRAVAME}/optin/sync`;

export const PATCH_ORDER_UPDATE = `${API_GRAVAME}/order/status`;

export const GET_BANK_ID_FINANCIAL_ADMIN = `${API_GRAVAME}/bank/list/financial-account`;

export const POST_BANK_ADMIN = `${API_GRAVAME}/bank/register`;

export const POST_GRAVAME_ADMIN = `${API_GRAVAME}/gravame/register`;

export const GET_RECEIVABLES_AVAILABLE_ADMIN = `${API_GRAVAME}/receivable/list/available`;

export const GET_RECEIVABLES_AVAILABLE_ONLINE = `${API_GRAVAME}/receivable/list/online`;

export const GET_RECEIVABLES_LIST = `${API_GRAVAME}/receivable/list`;

export const GET_CONTRACTOR_ARRANGEMENT_ADMIN = `${API_GRAVAME}/gravame/arrangements`;

export const GET_CONTRACTOR_ACQUIRER_ADMIN = `${API_GRAVAME}/gravame/acquirers`;
