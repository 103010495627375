import * as dateFns from 'date-fns';

export const isToday = (date) => {
  return dateFns.isToday(date);
};

export const format = (date, pattern = 'yyyy-MM-dd') => {
  return dateFns.formatDate(date, pattern);
};

export const diffInDays = (startDate, endDate) => {
  return dateFns.differenceInDays(startDate, endDate);
};

export const diffInHours = (startDate, endDate) => {
  return dateFns.differenceInHours(startDate, endDate);
};

export const sub = (date, options) => {
  return dateFns.sub(date, options);
};

export const add = (date, options) => {
  return dateFns.add(date, options);
};
