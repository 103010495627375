import React from 'react';
import { withTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Label } from 'reactstrap';

import StringUtils from '../../common/utils/StringUtils';

const CPF_MASK = '999.999.999-999';
const CNPJ_MASK = '99.999.999/9999-99';
const DEFAULT_MASK = '9999999999999999';

class LegalIdInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mask: DEFAULT_MASK,
      isValid: true,
      $error: null,
    };
  }

  validate = (valueWithoutMask, valueWithMask) => {
    if (
      valueWithoutMask !== null &&
      valueWithoutMask !== undefined &&
      valueWithoutMask.length > 0
    ) {
      try {
        const validCpfCnpj = StringUtils.validCpfCnpj(valueWithMask);
        if (!validCpfCnpj) {
          return { isValid: false, $error: 'Valor inválido' };
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      if (this.props['required'] === true) {
        return { isValid: false, $error: 'Valor obrigatorio' };
      }
    }

    return { isValid: true };
  };

  onChangeValidation = (event) => {
    const validate = this.onChangeValidationCore(event);
    this.setState(Object.assign(this.state, validate));
  };

  onChangeValidationCore = (event) => {
    const valueWithoutMask = event.target.value.replace(/\D/g, '').trim();
    const validate = this.validate(valueWithoutMask, event.target.value);

    if (this.props.onChangeValidation) {
      this.props.onChangeValidation(this.props.id, validate.isValid);
    }

    return validate;
  };

  setValue = (event) => {
    const newState = Object.assign({}, this.state);
    newState.value = event.target.value;

    const valueWithoutMask = event.target.value.replace(/\D/g, '').trim();

    if (valueWithoutMask.length <= 2) newState.mask = DEFAULT_MASK;
    else if (valueWithoutMask.length <= 11) {
      newState.mask = CPF_MASK;
    } else {
      newState.mask = CNPJ_MASK;
    }

    if (this.props.onChange) {
      this.props.onChange({
        target: { value: valueWithoutMask, name: event.target.name },
      });
    }

    const validate = this.onChangeValidationCore(event);
    if (!newState.isValid && validate.isValid) {
      newState.isValid = validate.isValid;
    }
    this.setState(newState);
  };

  render() {
    return (
      <React.Fragment>
        {this.props.label !== null && this.props.label !== undefined ? (
          <Label for={this.props.id} className='form-label'>
            {`${this.props.required === true ? '*' : ''}${this.props.label}`}
          </Label>
        ) : (
          ''
        )}

        <InputMask
          className={
            this.state.isValid ? 'form-control' : 'form-control is-invalid'
          }
          mask={this.state.mask}
          maskChar=''
          readOnly={
            this.props.readOnly !== null && this.props.readOnly !== undefined
              ? this.props.readOnly
              : false
          }
          value={
            this.props['value'] !== undefined && this.props['value'] !== null
              ? this.props['value']
              : null
          }
          alwaysShowMask={false}
          placeholder={this.props.placeholder}
          type='text'
          id={this.props.id}
          name={this.props.id}
          onBlur={this.onChangeValidation}
          onChange={this.setValue}
          maxLength={this.props.maxLength}
          disabled={this.props.disabled}
          defaultValue={this.props.defaultValue}
          autoComplete={this.props.autoComplete ? 'on' : 'off'}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(LegalIdInput);
