import * as url from '@cash2pay/helpers/url_helper';

import { HttpService } from './HttpService';

export class DocumentsService extends HttpService {
  constructor(http) {
    super(http);
  }

  async createOptinTerm({
    legalIdContractor,
    legalIdRegisterAgent,
    legalIdFinancialAgent,
    signer,
  }) {
    return this.http.post(`${url.API_GRAVAME}/legal-id-contract`, {
      legalIdContractor: legalIdContractor,
      legalIdRegisterAgent,
      legalIdFinancialAgent,
      contractTypeId: 1,
      signaturesSet: [
        {
          signerLegalId: signer.signerLegalId,
          fullName: signer.fullName,
          birth: signer.birth,
          email: signer.email,
          phone: signer.phone,
          requiredSignature: true,
          authLevel: 'EMAIL',
        },
      ],
    });
  }
}
