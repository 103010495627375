import * as brazilian from '@brazilian-utils/brazilian-utils';

export const isValidCpf = (cpf) => {
  return brazilian.isValidCPF(cpf);
};

export const isValidCnpj = (cnpj) => {
  return brazilian.isValidCNPJ(cnpj);
};

export const isLegalId = (legalId) => {
  return isValidCpf(legalId) || isValidCnpj(legalId);
};

export const isPhone = (phone) => {
  return brazilian.isValidPhone(phone);
};
