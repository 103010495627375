import { forwardRef } from 'react';
import Flatpickr from 'react-flatpickr';

import 'flatpickr/dist/themes/material_green.css';
import 'flatpickr/dist/l10n/pt.js';
import classNames from 'classnames';

const states = {
  error: 'is-invalid',
  valid: 'is-valid',
};

export const DatePicker = forwardRef(
  ({ className, options = {}, error, valid, ...props }, ref) => {
    const cn = classNames(
      'form-control',
      { [states.error]: !!error, [states.valid]: !!valid },
      className,
    );

    return (
      <Flatpickr
        ref={ref}
        className={cn}
        autoComplete='off'
        options={{
          altFormat: 'd/m/Y',
          dateFormat: 'Y-m-d',
          locale: 'pt',
          ...options,
        }}
        {...props}
        render={(props, ref) => <input ref={ref} {...props} />}
      />
    );
  },
);

DatePicker.displayName = 'DatePicker';
