import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import {
  FormGroup as Field,
  Label,
  Form as Root,
  FormFeedback as Message,
} from 'reactstrap';

const masks = {
  cnpj: '00.000.000/0000-00',
  cpf: '000.000.000-00',
  phone: '(00) 00000-0000',
};

const states = {
  disabled: 'border-secondary-light-subtle opacity-50',
  error: 'is-invalid',
  valid: 'is-valid',
};

const Input = forwardRef(
  ({ type = 'text', mask, error, valid, className, ...props }, ref) => {
    const cn = `form-control ${error ? states.error : ''} ${valid ? states.valid : ''} ${props.disabled ? states.disabled : ''} ${className ?? ''}`;

    return (
      <IMaskInput
        inputRef={ref}
        className={cn}
        mask={mask ? masks[mask] : false}
        type={type}
        {...props}
      />
    );
  },
);

Input.displayName = 'FormInput';

export const Form = {
  Root,
  Field,
  Input,
  Label,
  Message,
};
