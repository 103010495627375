import { Card, CardBody, List } from 'reactstrap';

import { CaretLeft, PlusCircle, User } from 'phosphor-react';

import { Button } from '@cash2pay/components/ui';
import { formatCnpj } from '@cash2pay/libs/formatters';

import { ButtonSelect } from '../button-select';
import { useCreateOptinTermFormContext } from '../create-optin-term-form-provider';

export const Company = () => {
  const { company, handleBackStep, selectSigner, selectOtherSigner } =
    useCreateOptinTermFormContext();

  return (
    <div>
      <h4 className='text-center mb-4'>Selecione o representante legal</h4>

      <Card>
        <CardBody>
          <List
            type='unstyled'
            className='mb-0 d-flex flex-column gap-1 text-muted'
          >
            <li>
              <strong className='fw-semibold'>Razão Social: </strong>
              <span className='fw-medium'>{company.name}</span>
            </li>
            <li>
              <strong className='fw-semibold'>Nome Fantasia: </strong>
              <span className='fw-medium'>{company.tradingName}</span>
            </li>
            <li>
              <strong className='fw-semibold'>Endereço: </strong>
              <span className='fw-medium'>
                {company.addresses.addressNumber},{' '}
                {company.addresses.publicPlace},{' '}
                {company.addresses.cityDescription}
              </span>
            </li>
            <li>
              <strong className='fw-semibold'>CNPJ: </strong>
              <span className='fw-medium'>{formatCnpj(company.legalId)}</span>
            </li>
          </List>
        </CardBody>
      </Card>

      <div className='d-flex flex-column gap-2 mt-4'>
        {company.associatedPartners.map(({ legalId, name }) => (
          <ButtonSelect
            key={legalId}
            Icon={User}
            title={name}
            titleDetail='(Sócio)'
            subtitle={`CPF: ${legalId}`}
            onClick={() =>
              selectSigner({
                legalId,
                name,
              })
            }
          />
        ))}
        <ButtonSelect
          Icon={PlusCircle}
          title='Adicionar outro responsável'
          variant='ghost'
          onClick={selectOtherSigner}
        />
      </div>

      <div className='modal-footer fixed-bottom justify-content-start'>
        <Button type='button' variant='secondary' onClick={handleBackStep}>
          <CaretLeft size={16} color='white' /> Voltar
        </Button>
      </div>
    </div>
  );
};
