import { Card, CardBody, List } from 'reactstrap';

import { CaretLeft, Info } from 'phosphor-react';

import { Button, Loading } from '@cash2pay/components/ui';
import { format } from '@cash2pay/libs/date';
import { formatCnpj, formatCpf, formatPhone } from '@cash2pay/libs/formatters';

import { useCreateOptinTermFormContext } from '../create-optin-term-form-provider';

export const Review = () => {
  const { form, company, isCompanySteps, handleBackStep } =
    useCreateOptinTermFormContext();

  const {
    getValues,
    formState: { isSubmitting },
  } = form;

  const { expirationDate, signer } = getValues();

  const isAssociatedParner = signer?.legalId.includes('*');

  return (
    <div>
      <h4 className='text-center mb-4'>Confirme os dados do cliente</h4>

      {isCompanySteps && (
        <>
          <strong>Usuário final recebedor</strong>
          <Card className='mt-2'>
            <CardBody>
              <List
                type='unstyled'
                className='mb-0 d-flex flex-column gap-2 text-muted'
              >
                <li className='d-flex justify-content-between'>
                  <strong className='fw-semibold'>Razão Social: </strong>
                  <span className='fw-medium'>{company.name}</span>
                </li>
                <li className='d-flex justify-content-between'>
                  <strong className='fw-semibold'>Nome Fantasia: </strong>
                  <span className='fw-medium'>{company.tradingName}</span>
                </li>
                <li className='d-flex justify-content-between'>
                  <strong className='fw-semibold'>Endereço: </strong>
                  <span className='fw-medium text-capitalize'>
                    {company.addresses.addressNumber},{' '}
                    {company.addresses.publicPlace},{' '}
                    {company.addresses.cityDescription}
                  </span>
                </li>
                <li className='d-flex justify-content-between'>
                  <strong className='fw-semibold'>CNPJ: </strong>
                  <span className='fw-medium'>
                    {formatCnpj(company.legalId)}
                  </span>
                </li>
              </List>
            </CardBody>
          </Card>
        </>
      )}

      <strong>
        {isCompanySteps ? 'Representante Legal' : 'Usuário final recebedor'}
      </strong>
      <Card className='mt-2'>
        <CardBody>
          <List
            type='unstyled'
            className='mb-0 d-flex flex-column gap-2 text-muted'
          >
            <li className='d-flex justify-content-between'>
              <strong className='fw-semibold'>Nome: </strong>
              <span className='fw-medium'>{signer.name}</span>
            </li>
            <li className='d-flex justify-content-between'>
              <strong className='fw-semibold'>E-mail: </strong>
              <span className='fw-medium'>{signer.email}</span>
            </li>
            <li className='d-flex justify-content-between'>
              <strong className='fw-semibold'>CPF: </strong>
              <span className='fw-medium'>
                {isAssociatedParner
                  ? signer.legalId
                  : formatCpf(signer.legalId)}
              </span>
            </li>
            <li className='d-flex justify-content-between'>
              <strong className='fw-semibold'>Telefone: </strong>
              <span className='fw-medium'>
                {formatPhone(signer.phone, {
                  countryCode: false,
                })}
              </span>
            </li>
          </List>
        </CardBody>
      </Card>

      <div>
        <strong className='fw-semibold'>Data de expiração da consulta: </strong>
        <span className='fw-medium'>
          {format(expirationDate, 'dd/MM/yyyy')}
        </span>
      </div>

      <div className='d-flex gap-2 text-muted mt-4'>
        <Info size={16} className='flex-shrink-0 mt-1' />
        <p>
          Após a confirmação, enviaremos um e-mail ao cliente para que ele possa
          assinar o termo de consulta da agenda de recebíveis.
        </p>
      </div>

      <div className='modal-footer fixed-bottom justify-content-between'>
        <Button
          type='button'
          variant='secondary'
          onClick={handleBackStep}
          disabled={isSubmitting}
        >
          <CaretLeft size={16} color='white' /> Voltar
        </Button>
        <Button type='submit' disabled={isSubmitting}>
          {isSubmitting ? <Loading /> : 'Confirmar'}
        </Button>
      </div>
    </div>
  );
};
