import { useCallback, useState } from 'react';
import { Form } from 'reactstrap';

import GenericModal from '@cash2pay/components/Common/GenericModal';

import { Stepper } from '../Progressbar';
import { DocumentTypes } from './config/document-type';
import { companySteps, personSteps } from './config/steps';
import {
  CreateOptinTermFormProvider,
  useCreateOptinTermFormContext,
} from './create-optin-term-form-provider';
import { DocumentSelect } from './document-select';

const CreateOptinTermForm = () => {
  const { handleFormSubmit, Step } = useCreateOptinTermFormContext();

  return (
    <Form onSubmit={handleFormSubmit}>
      <Step.Component />
    </Form>
  );
};

export const CreateOptinTermModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(0);
  const [documentType, setDocumentType] = useState(DocumentTypes.NONE);

  const handleNextStep = useCallback(() => {
    setStep((prevState) => prevState + 1);
  }, []);

  const handleBackStep = useCallback(() => {
    setStep((prevState) => prevState - 1);
  }, []);

  const handleDocumentTypeChange = useCallback((document) => {
    setDocumentType(document);
  }, []);

  const handleBackToDocumentTypeStep = useCallback(() => {
    setDocumentType(DocumentTypes.NONE);
  }, []);

  const isCompanySteps = documentType === DocumentTypes.COMPANY;

  const steps = isCompanySteps ? companySteps : personSteps;
  const Step = isCompanySteps ? steps[step] : steps[step];

  const stepperSteps = steps.reduce((acc, step) => {
    return step.label ? [...acc, step.label] : acc;
  }, []);

  return (
    <GenericModal
      isOpen={isOpen}
      title='Criar novo Cliente'
      callback={() => onClose()}
    >
      {DocumentTypes.NONE === documentType ? (
        <DocumentSelect onChange={handleDocumentTypeChange} />
      ) : (
        <>
          {!!Step.label && (
            <Stepper steps={stepperSteps} currentStep={step} className='mb-5' />
          )}
          <CreateOptinTermFormProvider
            Step={Step}
            isCompanySteps={isCompanySteps}
            onNextStep={handleNextStep}
            onBackStep={handleBackStep}
            onModalClose={onClose}
            onBackToDocumentTypeStep={handleBackToDocumentTypeStep}
          >
            <CreateOptinTermForm />
          </CreateOptinTermFormProvider>
        </>
      )}
    </GenericModal>
  );
};
