import { Check } from 'phosphor-react';

const spacer4 = '1.5rem';

export const Stepper = ({ steps, currentStep, className }) => (
  <div className={`position-relative mx-4 py-4 ${className}`}>
    <div className='progress' style={{ height: 2 }}>
      <div
        className='progress-bar'
        role='progressbar'
        style={{ width: `${((currentStep - 1) / (steps.length - 1)) * 100}%` }}
      />
    </div>

    {steps.map((step, index) => {
      const isCurrentStep = currentStep === index + 1;
      const isCompletedStep = currentStep > index + 1;

      const color =
        isCurrentStep || isCompletedStep ? 'bg-primary' : 'bg-light ';

      return (
        <div
          key={step}
          className={`position-absolute translate-middle`}
          style={{
            top: `calc(${spacer4} + 12px)`,
            left: `${(index / (steps.length - 1)) * 100}%`,
          }}
        >
          <div
            className={`mx-auto rounded-pill d-flex align-items-center justify-content-center ${color}`}
            style={{
              width: '2rem',
              height: '2rem',
              transition: 'color 0.2s ease-in, background-color 0.2s ease-in',
            }}
          >
            {isCompletedStep ? <Check size={16} weight='bold' /> : index + 1}
          </div>
          <span className='font-size-12 mt-2'>{step}</span>
        </div>
      );
    })}
  </div>
);
