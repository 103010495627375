import { Controller } from 'react-hook-form';

import { CaretLeft, CaretRight } from 'phosphor-react';

import { Button, DatePicker, Form, Loading } from '@cash2pay/components/ui';
import { add, format } from '@cash2pay/libs/date';

import { useCreateOptinTermFormContext } from '../create-optin-term-form-provider';

const dateOptions = {
  minDate: format(add(new Date(), { days: 1 }), 'yyyy-MM-dd'),
};

export const Origin = () => {
  const { handleBackStep, form, isCompanySteps } =
    useCreateOptinTermFormContext();

  const {
    register,
    control,
    formState: { isSubmitting, errors },
  } = form;

  return (
    <div>
      <h4 className='text-center mb-4'>Preencha as informações de origem</h4>

      <div className='d-flex flex-column gap-2'>
        <Form.Field>
          <Form.Label htmlFor='legal-id'>
            {isCompanySteps
              ? '*CNPJ do usuário final'
              : '*CPF do usuário final'}
          </Form.Label>
          <Form.Input
            id='legal-id'
            mask={isCompanySteps ? 'cnpj' : 'cpf'}
            error={!!errors?.legalId}
            {...register('legalId')}
          />
          <Form.Message>{errors?.legalId?.message}</Form.Message>
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor='expiration-date'>
            *Data de expiração da consulta
          </Form.Label>
          <Controller
            control={control}
            name='expirationDate'
            render={({ field, formState: { defaultValues } }) => (
              <DatePicker
                {...field}
                id='expiration-date'
                options={dateOptions}
                defaultValue={defaultValues.expirationDate}
                error={!!errors?.expirationDate}
                onChange={([date]) => field.onChange(date)}
              />
            )}
          />
          <Form.Message>{errors?.expirationDate?.message}</Form.Message>
        </Form.Field>
      </div>

      <div className='modal-footer fixed-bottom justify-content-between'>
        <Button
          type='button'
          variant='secondary'
          onClick={handleBackStep}
          disabled={isSubmitting}
        >
          <CaretLeft size={16} color='white' /> Voltar
        </Button>
        <Button type='submit' disabled={isSubmitting}>
          {isSubmitting ? (
            <Loading />
          ) : (
            <>
              Próximo <CaretRight size={16} color='white' />
            </>
          )}
        </Button>
      </div>
    </div>
  );
};
