import {
  companyOriginStepSchema,
  createCompanyOptinTermSchema,
  createPersonOptinTermSchema,
  personOriginStepSchema,
  signerStepSchema,
} from '../schemas';
import { Company } from '../steps/company';
import { Origin } from '../steps/origin';
import { Review } from '../steps/review';
import { Signer } from '../steps/signer';
import { Success } from '../steps/success';

export const StepIds = {
  ORIGIN: 'ORIGIN',
  COMPANY: 'COMPANY',
  SIGNER: 'SIGNER',
  REVIEW: 'REVIEW',
  SUCCESS: 'SUCCESS',
};

export const companySteps = [
  {
    id: StepIds.ORIGIN,
    label: 'Origem',
    Component: Origin,
    schema: companyOriginStepSchema,
  },
  {
    id: StepIds.COMPANY,
    label: 'Empresa',
    Component: Company,
  },
  {
    id: StepIds.SIGNER,
    label: 'Representante',
    Component: Signer,
    schema: signerStepSchema,
  },
  {
    id: StepIds.REVIEW,
    label: 'Confirmação',
    Component: Review,
    schema: createCompanyOptinTermSchema,
  },
  {
    id: StepIds.SUCCESS,
    Component: Success,
  },
];

export const personSteps = [
  {
    id: StepIds.ORIGIN,
    label: 'Origem',
    Component: Origin,
    schema: personOriginStepSchema,
  },
  {
    id: StepIds.SIGNER,
    label: 'Representante',
    Component: Signer,
    schema: signerStepSchema,
  },
  {
    id: StepIds.REVIEW,
    label: 'Confirmação',
    Component: Review,
    schema: createPersonOptinTermSchema,
  },
  {
    id: StepIds.SUCCESS,
    Component: Success,
  },
];
