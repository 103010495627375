import React, { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { withTranslation } from 'react-i18next';
import 'flatpickr/dist/themes/material_blue.css';
import 'flatpickr/dist/l10n/pt.js';
import {
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from 'reactstrap';

import { isArray } from 'lodash';

import DateTimeUtils from '../../common/utils/DateTimeUtils';

function InputDate(props) {
  const [state, setState] = useState({
    config: {
      altInput: true,
      allowInput: true,
      altFormat: 'd/m/Y',
      dateFormat: 'Y-m-d',
      locale: 'pt',
      defaultDate:
        !props.value && !isArray(props.value)
          ? DateTimeUtils.parse(props.value)
          : undefined,
      ...props.options,
    },
  });

  const [showPopover, setShowPopover] = useState(false);
  const [popoverBtnId] = useState(`popoverBtn${props.id}`);

  const value = state.config.mode === 'range' ? props.values : props.value;

  const setValue = (value) => {
    const newValue = DateTimeUtils.format(value[0], 'YYYY-MM-DD');
    if (state.config.mode === 'range') {
      const newValue1 = DateTimeUtils.format(value[1], 'YYYY-MM-DD');
      if (props.onChange) {
        props.onChange({
          init: { value: newValue, name: props.id },
          end: { value: newValue1, name: props.id },
        });
      }
    } else if (props.onChange) {
      props.onChange({
        target: { value: newValue, name: props.id },
      });
    }
  };

  useEffect(() => {
    if (props.values && isArray(props.values) && props.values.length > 1) {
      const value0 = DateTimeUtils.format(props.values[0], 'YYYY-MM-DD');
      const value1 = DateTimeUtils.format(props.values[1], 'YYYY-MM-DD');
      if (value0 && value1) {
        setState((prevState) => ({
          config: { ...prevState.config, defaultDate: [value0, value1] },
        }));
      }
    }
  }, [props.values]);

  return (
    <div className='form-group'>
      {props.label !== null && props.label !== undefined ? (
        <Label for={props.id} className='form-label'>
          {`${props.required === true ? '*' : ''}${props.label}`}
        </Label>
      ) : (
        <></>
      )}
      {props.popover !== null && props.popover !== undefined ? (
        <button
          id={popoverBtnId}
          className='btn btn-without-class'
          onClick={() => {
            setShowPopover(!showPopover);
          }}
        >
          <i className='bx bxs-help-circle font-size-20' />
        </button>
      ) : (
        <></>
      )}
      {props.popover !== null && props.popover !== undefined ? (
        <UncontrolledPopover
          placement='bottom'
          trigger='focus'
          target={popoverBtnId}
        >
          {props.label !== null && props.label !== undefined ? (
            <PopoverHeader>{props.label}</PopoverHeader>
          ) : (
            <></>
          )}
          <PopoverBody>{props.popover}</PopoverBody>
        </UncontrolledPopover>
      ) : (
        <></>
      )}

      <Flatpickr
        className={`form-control d-block ${props?.className || ''}`}
        id={props.id}
        name={props.id}
        onChange={setValue}
        placeholder={props.placeholder}
        options={state.config}
        defaultValue={props.defaultValue}
        disabled={props?.disabled}
        {...(value ? { value } : {})}
      />
    </div>
  );
}

export default withTranslation()(InputDate);
