import { createContext, useContext } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { getUserSession } from '@cash2pay/helpers/api_helper';
import { add } from '@cash2pay/libs/date';
import { showToast } from '@cash2pay/libs/toast';
import { documentsService, serproService } from '@cash2pay/services/http';
import { zodResolver } from '@hookform/resolvers/zod';

import { StepIds } from './config/steps';

const Context = createContext();

const defaultExpirationDateValue = add(new Date(), { days: 30 });

export const CreateOptinTermFormProvider = ({
  Step,
  onNextStep,
  onBackStep,
  onModalClose,
  onBackToDocumentTypeStep,
  isCompanySteps,
  children,
}) => {
  const [company, setCompany] = useState(null);

  const form = useForm({
    resolver: zodResolver(Step.schema),
    defaultValues: {
      expirationDate: defaultExpirationDateValue,
    },
  });

  const { setValue, handleSubmit, clearErrors } = form;

  const selectSigner = (signer) => {
    setValue('signer', signer);
    onNextStep();
  };

  const selectOtherSigner = () => {
    setValue('signer', null);
    onNextStep();
  };

  const handleBackStep = () => {
    if (Step.id === StepIds.ORIGIN) {
      onBackToDocumentTypeStep();
    } else {
      onBackStep();
    }

    clearErrors();
  };

  const getCompany = async (formData) => {
    if (company?.legalId === formData.legalId) {
      onNextStep();
      return;
    }

    try {
      const company = await serproService.getCompanyByLegalId(formData.legalId);

      setCompany(company);
      onNextStep();
    } catch (error) {
      if (error.response.status === 404) {
        showToast({
          type: 'error',
          message: 'Nenhuma empresa encontrada com o CNPJ informado',
        });
        return;
      }
      showToast({
        type: 'error',
        message: 'Houve um erro ao buscar tentar buscar os sócios',
      });
    }
  };

  const createOptinTerm = async (formData) => {
    const session = getUserSession();

    try {
      await documentsService.createOptinTerm({
        legalIdContractor: formData.legalId,
        legalIdRegisterAgent: session.registerAgent.legalId,
        legalIdFinancialAgent: session.financialAgent.legalId,
        signer: {
          signerLegalId: formData.signer.legalId,
          fullName: formData.signer.name,
          email: formData.signer.email,
          phone: formData.signer.phone,
        },
      });

      onNextStep();
    } catch {
      showToast({
        type: 'error',
        message: 'Houve um erro ao tentar criar o cliente.',
      });
    }
  };

  const submitForm = async (formData) => {
    const isCompanyOrginStep = Step.id === StepIds.ORIGIN && isCompanySteps;
    const isPersonOriginStep = Step.id === StepIds.ORIGIN && !isCompanySteps;

    if (isCompanyOrginStep) {
      await getCompany(formData);
    }
    if (isPersonOriginStep) {
      setValue('signer', { legalId: formData.legalId });
      onNextStep();
    }
    if (Step.id === StepIds.SIGNER) {
      onNextStep();
    }
    if (Step.id === StepIds.REVIEW) {
      await createOptinTerm(formData);
    }
  };

  return (
    <Context.Provider
      value={{
        form,
        Step,
        company,
        isCompanySteps,
        selectSigner,
        selectOtherSigner,
        handleBackStep,
        onModalClose,
        handleFormSubmit: handleSubmit(submitForm),
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useCreateOptinTermFormContext = () => useContext(Context);
