import * as url from '../../helpers/url_helper';
import { HttpService } from './HttpService';

export class OptinService extends HttpService {
  constructor(http) {
    super(http);
  }

  async createOptout(externalReference) {
    return this.http.post(url.POST_OPTOUT, { externalReference });
  }

  async create(optin) {
    return this.http.post(url.POST_OPTIN, optin);
  }
}
