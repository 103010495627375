export const ButtonSelect = ({
  Icon,
  title,
  titleDetail,
  subtitle,
  variant,
  ...props
}) => (
  <button
    type='button'
    className={`btn w-100 h-100 btn-select px-4 py-3 gap-3 ${variant === 'ghost' ? 'bg-transparent border-0 text-muted' : ''}`}
    {...props}
  >
    <Icon size={32} />
    <div className='d-flex flex-column'>
      <div className='d-flex align-items-center gap-1'>
        <span
          className='font-size-16 text-truncate'
          style={{ maxWidth: '300px' }}
        >
          {title}
        </span>
        {titleDetail && (
          <span className='font-size-12 fw-medium text-muted'>
            {titleDetail}
          </span>
        )}
      </div>
      {subtitle && <span className='font-size-12'>{subtitle}</span>}
    </div>
  </button>
);
