import { CaretLeft, CaretRight } from 'phosphor-react';

import { Button, Form, Loading } from '@cash2pay/components/ui';

import { useCreateOptinTermFormContext } from '../create-optin-term-form-provider';

export const Signer = () => {
  const { form, company, isCompanySteps, handleBackStep } =
    useCreateOptinTermFormContext();

  const {
    register,
    getValues,
    formState: { isSubmitting, errors },
  } = form;

  const signer = getValues('signer');

  const isAssociatedParner = company?.associatedPartners.some(
    ({ legalId }) => legalId === signer?.legalId,
  );

  return (
    <div>
      <h4 className='text-center mb-4'>
        Preencha as informações do representante legal
      </h4>

      <div className='d-flex flex-column gap-2'>
        <Form.Field>
          <Form.Label htmlFor='signer-cpf'>*CPF</Form.Label>
          <Form.Input
            id='signer-cpf'
            mask={isAssociatedParner ? null : 'cpf'}
            disabled={isAssociatedParner || !isCompanySteps}
            error={!!errors?.signer?.legalId}
            {...register('signer.legalId')}
          />
          <Form.Message>{errors?.signer?.legalId?.message}</Form.Message>
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor='signer-name'>*Nome Completo</Form.Label>
          <Form.Input
            id='signer-name'
            disabled={isAssociatedParner}
            error={!!errors?.signer?.name}
            {...register('signer.name')}
          />
          <Form.Message>{errors?.signer?.name?.message}</Form.Message>
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor='signer-email'>*Email</Form.Label>
          <Form.Input
            id='signer-email'
            type='email'
            error={!!errors?.signer?.email}
            {...register('signer.email')}
          />
          <Form.Message>{errors?.signer?.email?.message}</Form.Message>
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor='signer-phone'>*Telefone</Form.Label>
          <Form.Input
            id='signer-phone'
            type='tel'
            mask='phone'
            error={!!errors?.signer?.phone}
            {...register('signer.phone')}
          />
          <Form.Message>{errors?.signer?.phone?.message}</Form.Message>
        </Form.Field>
      </div>

      <div className='modal-footer fixed-bottom justify-content-between'>
        <Button
          type='button'
          variant='secondary'
          onClick={handleBackStep}
          disabled={isSubmitting}
        >
          <CaretLeft size={16} color='white' /> Voltar
        </Button>
        <Button type='submit' disabled={isSubmitting}>
          {isSubmitting ? (
            <Loading />
          ) : (
            <>
              Próximo <CaretRight size={16} color='white' />
            </>
          )}
        </Button>
      </div>
    </div>
  );
};
