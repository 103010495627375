import checkSuccess from '@cash2pay/assets/images/check-success.svg';
import { Button } from '@cash2pay/components/ui';

import { useCreateOptinTermFormContext } from '../create-optin-term-form-provider';

export const Success = () => {
  const { onModalClose } = useCreateOptinTermFormContext();

  return (
    <div className='text-center'>
      <img src={checkSuccess} alt='' height='88' className='mt-5' />

      <h4 className='mt-4'>Termo criado e enviado!</h4>

      <p className='mb-4 mt-4'>
        O termo de consulta para a agenda de recebíveis foi enviado para o
        e-mail do cliente. Em breve, ele estará disponível na listagem de
        termos.
      </p>

      <div className='modal-footer fixed-bottom justify-content-end'>
        <Button type='button' onClick={onModalClose}>
          Entendi
        </Button>
      </div>
    </div>
  );
};
