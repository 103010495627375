import * as z from 'zod';

import {
  unformatCnpj,
  unformatCpf,
  unformatPhone,
} from '@cash2pay/libs/formatters';
import { isPhone, isValidCnpj, isValidCpf } from '@cash2pay/libs/validators';

const schema = z.object({
  expirationDate: z
    .date({
      message: 'Preencha a data de expiração da consulta',
    })
    .min(new Date(), 'A data de expiração deve ser maior que a data de hoje'),
  signer: z.object({
    legalId: z
      .string({ message: 'Preencha o CPF' })
      .refine(
        (value) => {
          const isMaskedCpf = value.includes('*');
          return isMaskedCpf ? true : isValidCpf(value);
        },
        {
          message: 'Insira um CPF válido',
        },
      )
      .transform(unformatCpf),
    name: z
      .string({ message: 'Preencha o nome' })
      .nonempty({ message: 'Preencha o nome' }),
    email: z
      .string({ message: 'Preencha o e-mail' })
      .email({ message: 'Insira um e-mail válido' }),
    phone: z
      .string({ message: 'Preencha o telefone' })
      .refine(isPhone, { message: 'Insira um telefone válido' })
      .transform(unformatPhone),
  }),
});

export const createCompanyOptinTermSchema = schema.extend({
  legalId: z
    .string({ message: 'Preencha o CNPJ' })
    .refine(isValidCnpj, {
      message: 'Insira um CNPJ válido',
    })
    .transform(unformatCnpj),
});

export const createPersonOptinTermSchema = schema.extend({
  legalId: z
    .string({ message: 'Preencha o CPF' })
    .refine(isValidCpf, {
      message: 'Insira um CPF válido',
    })
    .transform(unformatCpf),
});

export const companyOriginStepSchema = createCompanyOptinTermSchema.pick({
  expirationDate: true,
  legalId: true,
});

export const personOriginStepSchema = createPersonOptinTermSchema.pick({
  expirationDate: true,
  legalId: true,
});

export const signerStepSchema = z.object({
  signer: schema.shape.signer,
});
