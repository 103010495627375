import * as url from '@cash2pay/helpers/url_helper';

import { HttpService } from './HttpService';

export class SerproService extends HttpService {
  constructor(http) {
    super(http);
  }

  async getCompanyByLegalId(legalId) {
    return this.http.post(`${url.API_GRAVAME}/people/data-serpro/company`, {
      legalIdReceivingEndUser: legalId,
    });
  }

  async getPersonByLegalId(legalId) {
    return this.http.post(`${url.API_GRAVAME}/people/data-serpro/person`, {
      legalIdReceivingEndUser: legalId,
    });
  }
}
