import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';

import classnames from 'classnames';
import { ArrowsClockwise, CheckCircle, XCircle } from 'phosphor-react';

import DateTimeUtils from '@cash2pay/common/utils/DateTimeUtils';
import Loader from '@cash2pay/components/Common/Loader';
import { receivablesService } from '@cash2pay/services/http';

import ReceivablesTimelineChartAcquirerByMonths from './ReceivablesTimelineChartAcquirerByMonths';
import ReceivablesTimelineChartChart from './ReceivablesTimelineChartChart';

const StatusIcon = ({ isOutdated, isOptout }) => {
  if (isOutdated && !isOptout) {
    return (
      <>
        <ArrowsClockwise
          size={20}
          className='align-bottom me-2'
          id='arrows-tooltip'
        />
        <UncontrolledTooltip target='arrows-tooltip'>
          A agenda de recebíveis está desatualizada.
        </UncontrolledTooltip>
      </>
    );
  }

  if (isOptout) {
    return (
      <>
        <XCircle
          size={20}
          className='align-bottom me-2 text-danger'
          id='x-circle-tooltip'
        />
        <UncontrolledTooltip target='x-circle-tooltip'>
          A atualização da agenda de recebíveis desse cliente foi desativada.
        </UncontrolledTooltip>
      </>
    );
  }

  return (
    <>
      <CheckCircle
        size={20}
        className='align-bottom me-2 text-success'
        id='check-tooltip'
      />
      <UncontrolledTooltip target='check-tooltip'>
        A agenda de recebíveis está atualizada.
      </UncontrolledTooltip>
    </>
  );
};

export const ReceivablesScheduleGraphic = (props) => {
  const [loading, setLoading] = useState(false);
  const [receivableAcquirersByMonths, setReceivableAcquirersByMonths] =
    useState(null);
  const [hasLoadedAcquirersByMonths, setHasLoadedAcquirersByMonths] =
    useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const translation = useTranslation();

  const tabs = [
    {
      title: translation.t('Monthly volume'),
      onclick: (index) => {
        setActiveTab(index);
      },
    },
    {
      title: translation.t('Free value'),
      onclick: (index) => {
        setActiveTab(index);
      },
    },
    {
      title: translation.t('Volume per acquirer'),
      onclick: handleGetAcquirersByMonths,
    },
  ];

  const today = DateTimeUtils.format(new Date(), 'DD/MM/YYYY');
  const tomorrow = DateTimeUtils.format(
    DateTimeUtils.subDays(new Date(), 1),
    'DD/MM/YYYY',
  );

  let lastUpdateDateOnly = DateTimeUtils.format(
    props.entity['lastUpdateDate'],
    'DD/MM/YYYY',
  );

  if (today === lastUpdateDateOnly) {
    lastUpdateDateOnly = 'hoje';
  } else if (tomorrow === lastUpdateDateOnly) {
    lastUpdateDateOnly = 'ontem';
  }

  const lastUpdateHourOnly = props.entity['lastUpdateTime'];

  function handleGetAcquirersByMonths() {
    getAcquirersByMonths();
  }

  const getAcquirersByMonths = async () => {
    if (hasLoadedAcquirersByMonths) return;

    setLoading(true);

    try {
      setHasLoadedAcquirersByMonths(true);

      const response = await receivablesService.getAllAcquirersUnitsByLegalId(
        props.entity.receivingEndUser.legalId,
      );

      setReceivableAcquirersByMonths(response['receivableAcquirersByMonths']);
      setActiveTab(2);
    } catch {
      //
    }

    setLoading(false);
  };

  return (
    <Col xl={9}>
      <Card className='card-height-100'>
        <CardBody>
          <Row>
            <Col xl={9}>
              <Nav className='nav-tabs-custom nav-justified'>
                {tabs.map((tab, index) => (
                  <NavItem key={index}>
                    <NavLink
                      className={classnames({
                        active: activeTab === index,
                      })}
                      onClick={() => tab.onclick(index)}
                    >
                      {tab.icon && (
                        <i className={`bx ${tab.icon} font-size-20 mr-5`} />
                      )}
                      <span>{tab.title}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Col>

            <Col lg={3} className='text-end'>
              <p className='text-muted mb-0'>
                <StatusIcon
                  isOptout={props.isOptout}
                  isOutdated={props.isOutdated}
                />
                {translation.t('Last update on')}
                <br />
                {lastUpdateDateOnly && (
                  <span>
                    {lastUpdateDateOnly} {translation.t('at')}{' '}
                    {lastUpdateHourOnly}
                  </span>
                )}
              </p>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <div className='mt-3'>
                <TabContent className='p-3 text-muted' activeTab={activeTab}>
                  <TabPane tabId={0}>
                    <ReceivablesTimelineChartChart
                      receivableUnits={
                        props.entity['receivableUnitsByMonths'] || []
                      }
                      showFreeValue={false}
                      showTotalConstitutedValue={true}
                    />
                  </TabPane>

                  <TabPane tabId={1}>
                    <ReceivablesTimelineChartChart
                      receivableUnits={
                        props.entity['receivableUnitsAvailableByDay'] || []
                      }
                      showFreeValue={true}
                      showTotalConstitutedValue={false}
                    />
                  </TabPane>

                  <TabPane tabId={2}>
                    <ReceivablesTimelineChartAcquirerByMonths
                      receivableUnits={receivableAcquirersByMonths}
                    />
                  </TabPane>
                </TabContent>

                {loading ? <Loader loading={loading} /> : ' '}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};
