import * as brazilian from '@brazilian-utils/brazilian-utils';

export const formatCnpj = (value) => {
  return brazilian.formatCNPJ(value);
};

export const formatCpf = (value) => {
  return brazilian.formatCPF(value);
};

export const formatPhone = (value, { countryCode = true }) => {
  const cleaned = ('' + value).replace(/\D/g, '');

  const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);

  if (match) {
    const intlCode = countryCode ? '+55 ' : '';
    return [intlCode, '(', match[1], ') ', match[2], '-', match[3]].join('');
  }

  return value;
};

export const unformatCnpj = (cnpj) => {
  return cnpj.replace(/\D/g, '');
};

export const unformatCpf = (cpf) => {
  return cpf.replace(/\D/g, '');
};

export const unformatPhone = (phone) => {
  return phone.replace(/\D/g, '');
};
