import { Buildings, User } from 'phosphor-react';

import { ButtonSelect } from './button-select';
import { DocumentTypes } from './config/document-type';

export const DocumentSelect = ({ onChange }) => (
  <div className='d-flex flex-column gap-2 mt-4'>
    <h4 className='text-center mb-4'>
      Selecione o tipo de documento do cliente
    </h4>

    <ButtonSelect
      Icon={Buildings}
      title='Empresa'
      subtitle='CNPJ'
      onClick={() => onChange(DocumentTypes.COMPANY)}
    />
    <ButtonSelect
      Icon={User}
      title='Pessoa'
      subtitle='CPF'
      onClick={() => onChange(DocumentTypes.PERSON)}
    />
  </div>
);
